import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../fxbgdsaredground.png';
import '../App.css';
import {faFacebookSquare, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";

function NavBar() {
    return (
        <div className="Nav">
            <a href="/" className="logo"><img src={logo} /></a>
            <ul className="menu hide">
                <li><a href="/">Home</a></li>
                <li><a href="/#contact">Contact</a></li>
            </ul>
            <ul className="social">
                <li><a href="https://twitter.com/fxbgdsa"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="https://www.facebook.com/fxbgdsa"><FontAwesomeIcon icon={faFacebookSquare} /></a></li>
                <li><a href="https://www.instagram.com/fxbgdsa/"><FontAwesomeIcon icon={faInstagram} /></a></li>
            </ul>
        </div>
    );
}

export default NavBar;
