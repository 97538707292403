import React, { useState } from 'react';
import header from '../images/5ed4a2b10d826.image.jpg';
import bearcat from '../images/bearcat.png';
import '../App.css';

function Home() {
    const initialState = {body: ''};
    const [state, setState] = useState(initialState);

    return (
        <div className="Home">
            <div className="header" style={{ backgroundImage: `url(${header})` }}>
                <h1>Demand Change</h1>
                <h4>We have the momentum, but where do we go from here?</h4>
            </div>
            <div className="content">
                <h4>It doesn't have to be like this. We can end police violence in our city.</h4>
                <div className="videoHolder">
                    <iframe src="https://streamable.com/e/nh0n7h?loop=0" frameBorder="0" width="100%" height="100%"
                            allowFullScreen
                            className="video" />
                </div>
                <p>The protests in our city have highlighted the disconnect between the Fredericksburg Police and the community they swore to protect and serve. The hurt runs deep and the abuses in response to the protests only reinforce the distrust the community feels with their officers. There is no quick fix. It will take a years-long, concerted effort to repair the damage done. We at the Fredericksburg DSA have compiled a list of legal protections and policy changes that work in other states and cities and could help rebuild our community.</p>
                <p>Other organizations like the <a href="https://fxbgpowercollective.squarespace.com/" target="_blank" rel="noreferrer noopener">Fredericksburg Power Collective</a> and <a href="https://www.joincampaignzero.org/" target="_blank" rel="noreferrer noopener">Campaign Zero</a> have great ideas and you should visit their websites and learn more. These issues we list below are the most critical areas we need to address in our city right now.</p>
            </div>
            <h2 style={{ backgroundImage: `url(${header})` }}><span>Tactics</span></h2>
            <div className="content">
                <h3>1. Criminalize Chokeholds/Neck Restraints</h3>
                <p>The fact that the words &#39;I Can&#39;t Breathe&#39; is a rallying cry is a national embarrassment. We want to criminalize the use of tactics that are illegal to use even on animals. Fredericksburg disallows police from using these tactics, but that's not enough. We need legal ramifications for these tactics. We want a law like <a href="http://www.leg.state.co.us/clics/clics2016a/csl.nsf/billcontainers/F5A2A727B31083DD87257F45007EDDCE/$FILE/1264_01.pdf" target="_blank" rel="noreferrer noopener">Colorado&#39;s HB12-64</a> which makes it a crime for any officer to use a chokehold or neck restraint.</p>
                <h3>2. Enforce De-escalation</h3>
                <p>We need to enforce the use of de-escalation tactics in our city. <a href="https://www.joincampaignzero.org/brokenwindows" target="_blank" rel="noreferrer noopener">Broken Windows Policing</a> overwhelmingly affects young and minority communities and allows any officer to cater to their own personal biases. We need to end that practice. We need to implement de-escalation training (similar to <a href="https://www.congress.gov/bill/116th-congress/house-bill/2329/text" target="_blank" rel="noreferrer noopener">HB 2329</a>) so that every Fredericksburg Police officer has non-violent methods at their disposal, but that doesn&#39;t go far enough. We need legal protection similar to <a href="https://www.lawserver.com/law/state/tennessee/tn-code/tennessee_code_39-11-620" target="_blank" rel="noreferrer noopener">Tennessee Code 39-11-620</a>. We demand a law which legally requires police to use every available alternative before shooting, applying force, or arresting.</p>
                <h3>3. End Qualified Immunity</h3>
                <p>Qualified Immunity is the rule that if an officer breaks the law in discharge of his duties, they are not held responsible. When this sweeping policy applies, officers feel they have permission to abuse with impunity. Congressman Justin Amash is introducing the <a href="https://www.nytimes.com/reuters/2020/06/01/us/politics/01reuters-minneapolis-police-congress.html" target="_blank" rel="noreferrer noopener">End Qualified Immunity Act</a> to fix this problem, but we can neither wait for Congress nor trust them to get it right. We need to end it here.</p>
            </div>
            <h2 style={{ backgroundImage: `url(${header})` }}><span>Equipment</span></h2>
            <div className="content">
                <h3>4. Ban the BearCat</h3>
                <img alt="Fredericksburg PD's BearCat" src={ bearcat } className="bearcat" /><p>The Fredericksburg PD&#39;s Lenco BearCat (pictured above) has no purpose other than to intimidate the community they have sworn to protect and serve. We are a small city and have a larger problem with parking enforcement than IEDs. We need to get rid of it.</p>
                <div className="clear" />
                <h3>5. Ban Teargas</h3>
                <p>The <a href="https://www.icrc.org/en/war-and-law/treaties-customary-law/geneva-conventions" target="_blank" rel="noreferrer noopener">Geneva Conventions</a> have banned the use of chemical weapons - including teargas - from warzones. If it&#39;s a cruel and inhumane tactic to use in a war, it&#39;s a tactic we need to ban from our city.</p>
                <h3>6. Ban any/all Military Equipment</h3>
                <p>The military equipment local law enforcement uses fundamentally changes the posture of the police from <strong>Protect and Serve</strong> to <strong>Occupy and Dominate</strong>. We want a law like <a href="https://legiscan.com/MT/text/HB330/id/1198338" target="_blank" rel="noreferrer noopener">Montana&#39;s HB330</a> which bans police from receiving military weapons from the federal government including tanks, armored vehicles, drones, grenade launchers, aircraft.</p>
                <h3>7. Investigate Drone/Stingray/AI/Facial Recognition Usage</h3>
                <p>Technology is advancing faster than law enforcement can responsibly use it. Drones have been spotted above the protests and reports have been heard of the use of <a href="https://www.eff.org/pages/cell-site-simulatorsimsi-catchers" target="_blank" rel="noreferrer noopener">Stingray</a> spying devices. Virginia&#39;s HB1408 requires a warrant for the use of those spying devices but has loopholes that could have applied to the protests. We need a full accounting of the scope of drone usage in our city, we need to get rid of the Stingray device the Fredericksburg PD owns (which is <a href="https://www.rt.com/usa/369062-virginia-stingray-captures-voice-communication/" target="_blank" rel="noreferrer noopener">embarrassingly expensive and ineffective</a>), and we need to make sure the Fredericksburg PD is not using facial recognition or other buggy and racially-biased policing tools.</p>
            </div>
            <h2 style={{ backgroundImage: `url(${header})` }}><span>Trust</span></h2>
            <div className="content">
                <h3>8. Establish a Community Oversight Board</h3>
                <p>We have a Citizen&#39;s Advisory Panel and we are grateful for the work they do, but we need to go further. We need to establish a Community Oversight Board that has power to pass dept policies, fire officers &amp; the police chief, select the candidates for police chief, and investigate misconduct. This board will be made up of community leaders, appointees from the Mayor, and representatives from the Police department and act as a public forum for citizens to bring their concerns to and address any grievances. The template we want to follow is <a href="https://ballotpedia.org/Oakland,_California,_Civilian_Police_Commission,_Measure_LL_(November_2016)" rel="noreferrer noopener" target="_blank">Oakland's Measure LL</a>.</p>
                <h3>9. Ban the Shuffling of Problem Officers</h3>
                <p>The officer who <a href="https://www.fredericksburg.com/news/local/fredericksburg-settles-lawsuit-alleging-excessive-force-in-use-of-taser-against-motorist/article_e857dbad-4613-56e8-9056-779e71fc41bd.html" target="_blank" rel="noreferrer noopener">assaulted an elderly black man on Cowan Boulevard in 2015</a> went on to work for the <a href="https://www.facebook.com/SpotsySO/posts/1822423597832708" target="_blank" rel="noreferrer noopener">Stafford PD</a>. This is unacceptable. Police need to be held to the highest standard, so we are demanding a law similar to <a href="https://www.cga.ct.gov/2015/ACT/PA/2015PA-00004-R00HB-07103SS1-PA.htm" target="_blank" rel="noreferrer noopener">Connecticut&#39;s HB 7103 Section 6</a> which makes it illegal for police departments to hire officers who were previously fired or who resigned while being investigated for serious misconduct and/or excessive force. Further, we demand that disciplinary records are made public similar to Maine Statutes <a href="http://legislature.maine.gov/statutes/30-A/title30-Asec503.html" rel="noreferrer noopener" target="_blank">&sect; 503(1)(B)(5)</a>, <a href="http://www.mainelegislature.org/legis/statutes/30-A/title30-Asec2702.html" rel="noreferrer noopener" target="_blank">&sect; 2702(1)(B)(5)</a>, and <a href="http://legislature.maine.gov/statutes/5/title5sec7070.html" rel="noreferrer noopener" target="_blank">&sect; 7070(2)(E)</a>.</p>
                <h3>10. Stop Policing Schools</h3>
                <p>The school-to-prison pipeline is a large problem we need to address. Kids who spend their school lives over-policed and constantly under threat of arrest cannot properly learn and develop a distrust of all Police officers. We demand that school resource officers be disarmed and that they do not interfere with school discipline in non-criminal school violations such as fighting, bullying, and classroom behavioral issues. Instead, guidance counselors and trusted faculty should be the first responders on the scene. (For more information, visit the <a href="https://fxbgpowercollective.squarespace.com/" target="_blank" rel="noreferrer noopener">Fredericksburg Power Collective</a>)</p>
            </div>
            <h2 style={{ backgroundImage: `url(${header})` }}><span>Take Action</span></h2>
            <div className="content">
                <p>The City Council is meeting on July 14th and we want to flood them with Public Comments in support of these policies just like <a href="https://www.fredericksburg.com/news/local/fredericksburg-city-council-gets-earful-about-police-response-at-protests/article_f51d8a85-2f6f-5112-af2e-6018ab045474.html?fbclid=IwAR3c1CgfQN-l2SQRzjGgzRaQKhlLkQ-qGnotRgBMaxZpCWqXcoIbCaQaqWQ" target="_blank" rel="noreferrer noopener">we did on June 9th</a>! If you support these ideas and want to see them implemented in your city, write about it below and send it to them to read aloud at the next meeting. You have to be a Fredericksburg resident and must identify yourself by name and address, including zip code, limit your remarks to 5 minutes or less (read aloud). Be descriptive. Tell your story and write about how these policies can make your city better.</p>
                <textarea value={state.body} onChange={event => setState({ body: event.target.value })}>Write your message to the City Council here</textarea>
                <a href={`mailto:tlacey@fredericksburgva.gov?subject=Public%20Comment%20for%20the%20July%2014th%20City%20Council%20Meeting&body=${encodeURIComponent(state.body)}`}
                   className="action"
                   target="_blank"
                   rel="noreferrer noopener">
                    Generate Email
                </a>
            </div>
        </div>
    );
}

export default Home;
